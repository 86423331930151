import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const RestTester = makeShortcode("RestTester");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "geocoder-api"
    }}>{`Geocoder API`}</h1>
    <p>{`The geocoder API is based on the open source product "Pelias". You'll find official Pelias documentation on `}<a parentName="p" {...{
        "href": "https://mapzen.com/documentation/search/"
      }}>{`GitHub`}</a>{`.`}</p>
    <h2 {...{
      "id": "reference"
    }}>{`Reference`}</h2>
    <h3 {...{
      "id": "autocomplete"
    }}>{`/autocomplete`}</h3>
    <p>{`Search for features using a search string. This is useful for autosuggest search fields.`}</p>
    <ExpandablePanel title="Try it!" mdxType="ExpandablePanel">
    <RestTester url="https://api.entur.io/geocoder/v1/autocomplete" queryParameters={[{
        name: 'text',
        type: 'string',
        defaultValue: 'Oslo'
      }, {
        name: 'size',
        type: 'number',
        defaultValue: 20
      }, {
        name: 'lang',
        type: 'string',
        defaultValue: 'no'
      }]} mdxType="RestTester" />
    </ExpandablePanel>
    <h3 {...{
      "id": "reverse"
    }}>{`/reverse`}</h3>
    <p>{`Find features within a geographical area, defined by a coordinate and a radius.`}</p>
    <ExpandablePanel title="Try it!" mdxType="ExpandablePanel">
    <RestTester url="https://api.entur.io/geocoder/v1/reverse" queryParameters={[{
        name: 'point.lat',
        type: 'number',
        defaultValue: '60.1'
      }, {
        name: 'point.lon',
        type: 'number',
        defaultValue: '10.04'
      }, {
        name: 'boundary.circle.radius',
        type: 'number',
        defaultValue: 1
      }, {
        name: 'size',
        type: 'number',
        defaultValue: 10
      }, {
        name: 'layers',
        type: 'string',
        defaultValue: 'address,locality'
      }]} mdxType="RestTester" />
    </ExpandablePanel>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p>{`In addition to official Pelias parameters, we have also added our own enhancements to support local- or technical needs:`}</p>
    <h3 {...{
      "id": "size"
    }}>{`Size`}</h3>
    <p>{`Governs the maximum number of results.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Methods`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[1-100]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&size=30"
            }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=ha...`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "boundaries"
    }}>{`Boundaries`}</h3>
    <p>{`Allows filtering by geographical region.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Methods`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boundary.country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO 3166-1 alpha-3 country code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`County code (ISO 3166-1 alpha-3) (`}<a parentName="td" {...{
              "href": "https://no.wikipedia.org/wiki/ISO_3166-1_alfa-3"
            }}>{`https://no.wikipedia.org/wiki/ISO_3166-1_alfa-3`}</a>{`): `}<a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&boundary.country=NOR"
            }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&bo...`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boundary.county_ids`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma separated list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "https://register.geonorge.no/sosi-kodelister/fylkesnummer-alle"
                }}>{`Norwegian county numbers`}</a>{` are with prefix "KVE:TopographicPlace:"`}</li><li>{` `}<a parentName="td" {...{
                  "href": "https://www.scb.se/en/finding-statistics/regional-statistics/regional-divisions/counties-and-municipalities/counties-and-municipalities-in-numerical-order/"
                }}>{`Swedish county numbers`}</a>{` are with prefix "LAN:TopographicPlace": `}<a parentName="td" {...{
                  "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&boundary.county_ids=KVE:TopographicPlace:02,KVE:TopographicPlace:03"
                }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&bo...`}</a></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boundary.locality_ids`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma separated list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><a parentName="td" {...{
                  "href": "https://register.geonorge.no/sosi-kodelister/kommunenummer"
                }}>{`Norwegian municipality numbers`}</a>{` are with prefix "KVE:TopographicPlace"`}</li><li><a parentName="td" {...{
                  "href": "https://www.scb.se/en/finding-statistics/regional-statistics/regional-divisions/counties-and-municipalities/counties-and-municipalities-in-numerical-order/"
                }}>{`Swedish municipality numbers`}</a>{` are with prefix "LAN:TopographicPlace" : `}<a parentName="td" {...{
                  "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=tro&boundary.locality_ids=KVE:TopographicPlace:5001,KVE:TopographicPlace:5004"
                }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=tro&bo...`}</a></li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "tariff-zones"
    }}>{`Tariff zones`}</h3>
    <p>{`Allows filtering stops by tariff zone.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Methods`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tariff_zone_authorities`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma separated list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&tariff_zone_authorities=RUT,BRA"
            }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&ta...`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tariff_zone_ids`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comma separated list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&tariff_zone_ids=BRA:TariffZone:115,BRA:TariffZone:120"
            }}>{`https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&ta...`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "multimodal"
    }}>{`multiModal`}</h3>
    <p>{`Controls whether the search returns multimodal stops, child stops of the multimodal stops, or both. Does not affect monomodal stops.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Methods`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`multiModal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`parent (default)`}</inlineCode>{`, `}<inlineCode parentName="td">{`child`}</inlineCode>{`, `}<inlineCode parentName="td">{`all`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald"
            }}>{`Only multimodal stops without children:`}</a>{` `}<a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&multiModal=child"
            }}>{`Only child stops`}</a>{` `}<a parentName="td" {...{
              "href": "https://api.entur.io/geocoder/v1/autocomplete?lang=no&text=hald&multiModal=all"
            }}>{`Both`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`autocomplete`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "advanced-parameters"
    }}>{`Advanced parameters`}</h2>
    <p>{`We have exposed the following pelias-internal parameters in the autocomplete endpoint, related to boosting results according to proximity when the `}<inlineCode parentName="p">{`focus.point`}</inlineCode>{` parameter is given in the query.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`focus.weight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Base weight to be applied to boosting results based on location. This value will be multiplied by a factor determined by decay function and scale.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`focus.function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`linear,exp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`linear`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Which decay function to apply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`focus.scale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>0km`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2500km`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the rate of decay, i.e. at which distance from the given location the scoring will be given the boost factor of the default decay value, which is 0.5.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Given the default values, a result at the given location will be given a boost factor of `}<inlineCode parentName="p">{`15x1=15`}</inlineCode>{`, and a result at 2500 km away will be given a boost factor of `}<inlineCode parentName="p">{`15x0.5=7.5`}</inlineCode>{`. Results located 5000 km away and beyond will not be boosted with the linear function: since `}<inlineCode parentName="p">{`15x0=0`}</inlineCode>{`. Locations in between will be boosted with a factor following a linear curve through the decay point.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Let's say weight is given a value of `}<inlineCode parentName="p">{`50`}</inlineCode>{`, function is set to `}<inlineCode parentName="p">{`exp`}</inlineCode>{` and scale is set to `}<inlineCode parentName="p">{`100km`}</inlineCode>{`. A result at the given location will be given av boost of `}<inlineCode parentName="p">{`50x1=50`}</inlineCode>{`. Results located 100 km away will be given a boost of `}<inlineCode parentName="p">{`50x0.5=25`}</inlineCode>{`. The boost factor for other locations will follow an exponential curve through the decay point approaching (but never reaching) 0.`}</p>
      </li>
    </ol>
    <p>{`Additional reading: `}<a parentName="p" {...{
        "href": "https://www.elastic.co/guide/en/elasticsearch/guide/current/decay-functions.html"
      }}>{`https://www.elastic.co/guide/en/elasticsearch/guide/current/decay-functions.html`}</a></p>
    <h2 {...{
      "id": "definitions"
    }}>{`Definitions`}</h2>
    <h3 {...{
      "id": "layers"
    }}>{`Layers`}</h3>
    <p>{`Due to limitations in Pelias the definitions of layers has been re-defined and limited to the following two layers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Layer`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`venue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stops`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`POI, streets, addresses, stop groups`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "categories"
    }}>{`Categories`}</h3>
    <p>{`Used for subtyping within layers.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Layer`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`venue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`onstreetBus, onstreetTram, airport, railStation, metroStation, busStation, coachStation, tramStation, harbourPort, ferryPort, ferryStop, liftStation, vehicleRailInterchange, other Stops from NSR. On multimodal stops, these values are only found on their child stops.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GroupOfStopPlaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stop groupings from NSR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`POI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Collected from OpenStreetMap.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Street address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Addresses from 'Kartverket'.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Street`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Street names from 'Kartverket'. Coordinate is the median address-location.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Places`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Placenames from 'Kartverket'. Currently only 'tettsted' and 'bydel'. See `}<a parentName="td" {...{
              "href": "https://www.kartverket.no/globalassets/standard/sosi-standarden-del-1-og-2/sosi-standarden/stedsnavn.pdf"
            }}>{`kartverket.no`}</a>{` for complete list of available places.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "counties-and-municipalities"
    }}>{`Counties and municipalities`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://register.geonorge.no/sosi-kodelister/fylkesnummer-alle"
        }}>{`Norwegian county numbers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://register.geonorge.no/sosi-kodelister/kommunenummer"
        }}>{`Norwegian municipality numbers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.scb.se/en/finding-statistics/regional-statistics/regional-divisions/counties-and-municipalities/counties-and-municipalities-in-numerical-order/"
        }}>{`Swedish county and municipality`}</a></li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Layer`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`county`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of county (fylke)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nordland`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`county_gid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of county (prefixed with "whosonfirst:county")`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`whosonfirst:county:KVE:TopographicPlace:18`}<br />{`whosonfirst:county:LAN:TopographicPlace:14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locality`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of municipality (kommune)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Oslo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locality_gid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of county (prefixed with "whosonfirst:locality")`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`whousonfirst:locality:KVE:TopographicPlace:0301`}<br />{`whousonfirst:locality:LAN:TopographicPlace:1486`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      